.App {
  min-height: 100vh;
  /* background-image: url('../public/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: repeat; */
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; /* Ensure full width coverage */
  overflow-x: hidden; /* Prevent horizontal scrolling if it's coming from the image */
}

.content-container {
  /* Add styles for your content container */
  padding: 20px;
  /* You might want to add some background color with opacity for better readability */
  background-color: rgba(255, 255, 255, 0.8);
}
.photo-gallery {
  
  width: 100vw;   /* Full viewport width */
  margin: 0;      /* No margin */
  padding: 0;     /* No padding */
}

.photo-gallery img {
  width: 100%;  /* Make the image take up full width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure it covers the area without distortion */
}

.event-info, .rsvp {
  min-height: 100vh;
  padding: 2rem;
}

.event-info {
  background-color: white;
}

.landing-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.landing-page.sliding {
  transform: translateX(-100%);
}


.page-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
}

.page-container.landing {
  z-index: 10;
}

.page-container.inner {
  z-index: 5;
  transform: translateX(100%);
  overflow-y: auto; /* This allows vertical scrolling */
}

.page-container.inner.sliding {
  transform: translateX(0);
}

.page-container.landing.sliding {
  transform: translateX(-100%);
}

.page-container.loading {
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}